<template>
  <v-container>
    <v-card
      elevation="16"
      outlined
      shaped
      tile
    >
      <h1>Golf With Your Friends!</h1>
      <h2>Forest Course</h2>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Hole
              </th>
              <th v-for="n in 18" :key="n">
                <template>
                  <th class="text-left">
                    {{n}}
                  </th>
                </template>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Kronk</td>
              <td>1.75</td>
              <td>2.25</td>
              <td>2.5</td>
              <td>2.74</td>
              <td>2.5</td>
              <td>2.25</td>
              <td>2.21</td>
              <td>1.25</td>
              <td>2.75</td>
              <td>no data</td>
              <td>no data</td>
              <td>no data</td>
              <td>no data</td>
              <td>no data</td>
              <td>no data</td>
              <td>no data</td>
              <td>no data</td>
              <td>no data</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
        
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'GolfWithYourFriends',

    data: () => ({
      courses: [
          {
              name: "Golf with Friends",
              header: "gwfHeader.png",
          },
      ],
    }),
  }
</script>
